<template>
  <div id="pay">
    <van-action-sheet @close="handleClose" v-model="show" title="选择支付方式">
      <van-radio-group v-model="payMethod">
        <van-cell-group>
          <van-cell
            clickable
            @click="payMethod = '1'"
          >
            <template slot="title">
              <div class="flex align-center">
                <van-icon color="#3eb129" size="28" name="wechat-pay" />
                <span style="font-size: 0.32rem; margin-left: 10px">微信</span>
              </div>
            </template>
            <template #right-icon>
              <van-radio name="1" checked-color="#67d752" icon-size="18px" />
            </template>
          </van-cell>
          <van-cell v-if="!isWeixin && !isWeixinWork" clickable @click="payMethod = '2'">
            <template slot="title">
              <div class="flex align-center">
                <van-icon color="#1195da" size="28" name="alipay" />
                <span style="font-size: 0.32rem; margin-left: 10px"
                  >支付宝</span
                >
              </div>
            </template>
            <template #right-icon>
              <van-radio name="2" checked-color="#67d752" icon-size="18px" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <van-button
        type="primary"
        style="
          width: 92%;
          border-radius: 5px;
          margin: 0.7rem 4% 0.5rem;
          font-size: 15px;
        "
        @click="pay"
        color="linear-gradient(to right, #0d4bed, #0293fc)"
        block
        >立即支付 ￥{{ price }}</van-button
      >
    </van-action-sheet>
  </div>
</template>

<script>
import commonApi from "@/api/common";
export default {
  name: "Pay",
  components: {},
  props: {
    price: Number, // 支付金额
  },
  data() {
    return {
      // 分享弹出
      show: true,
      isWeixin: commonApi.isWeiXin(),
      isWeixinWork: commonApi.isWxwork(),
      // 支付方式
      payMethod: commonApi.isWeiXin() || commonApi.isWxwork() ? "1" : "2",
    };
  },
  created() {
    console.log(this.isWeixin);
    console.log(this.isWeixinWork);
  },
  watch: {},
  mounted() {},
  methods: {
    /* 立即支付 */
    pay() {
      this.$emit("pay", this.payMethod);
      // this.$router.push({name: 'result'})
    },
    /* 关闭弹出框回调 */
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped  lang="scss">
</style>
<style>
#pay .van-cell {
  padding: 15px 22px;
}
#pay .van-cell::after {
  border-bottom: #f0f0f2;
}
#pay [class*="van-hairline"]::after {
  border-bottom: #f0f0f2;
}
#pay .van-action-sheet__header {
  text-align: left;
  margin-left: 22px;
  font-weight: bold;
}
</style>
