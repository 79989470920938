// import axios from 'axios';
// import {Message} from 'element-ui';
/**
 *  宽带模块接口
 * **/

let PREFIX = '/mobile/kdform/'; // TODO 接口前缀( 宽带 —— 不要轻易改动 在开始接单和数据权限组中有类型判断用到)
let KKPREFIX = '/mobile/kkform/'; // 开卡接口
let YXPREFIX = '/mobile/yxform/'; // 开卡接口
export default {
  PREFIX: `${PREFIX}`,
  KKPREFIX: `${KKPREFIX}`,
  YXPREFIX: `${YXPREFIX}`
}
